import { Helmet, HelmetProvider } from "react-helmet-async";
import React, { useEffect } from "react";

import AOS from "aos";
import Layout from "../components/layout";

import HeroImage from "../images/memorials/life-hospice-memorial.webp";
import HeroTabletImage from "../images/memorials/life-hospice-memorial.webp";
import HeroMobileImage from "../images/memorials/life-hospice-memorial-mobile.webp";
import IconPhone from "../images/phone-call-white.svg";
import HeroBanner from "../components/HeroBanner";
import PersonMemorialInfoCard from "../components/PersonMemorialInfoCard";
import GetInTouchSection from "../components/GetInTouchSection";

const Memorials = () => {
  useEffect(
    () => {
      AOS.init({
        duration: 2000,
        once: true,
      });
    },
    [],
    AOS
  );

  return (
    <HelmetProvider>
      <Layout>
        <Helmet>
          <link rel="canonical" href="https://hospicecare.life/" />
        </Helmet>
        <HeroBanner
          paragraphWidth="65%"
          paragraphFontSize="22px"
          HeroImage={HeroImage}
          HeroMobileImage={HeroMobileImage}
          HeroTabletImage={HeroTabletImage}
          title="IN LOVING MEMORY"
          paragraph="We honor the lives of those who have passed, cherishing the
              memories they leave behind. Each person touched our hearts and
              enriched our community with their unique spirit and love."
          secondaryParagraph="As we remember them, we find comfort in knowing they are at peace.
              Let us celebrate their lives, embrace the love they gave, and find
              solace in the beautiful moments we shared. Forever in our hearts,
              their light continues to shine."
        />
        <PersonMemorialInfoCard />
        <GetInTouchSection
          icon={IconPhone}
          description="Call us at 973-607-4930 for in-home care services. At LIFE HOSPICE, we’re dedicated to delivering excellence and compassion. Discover more about our experienced home care services today."
          mailto="isabella@hospicecare.life"
        />
      </Layout>
    </HelmetProvider>
  );
};

export default Memorials;
