import React from "react";
import { graphql, StaticQuery, Link } from "gatsby";
import { transformToSlug } from "../Utils/transformToSlug";
import Markdown from "../Common/Markdown";

import {
  Card,
  CardImage,
  CardText,
  DataContainer,
  DateContainer,
  DateTextStyles,
  FullNameContainer,
  MainContainer,
  DescriptionContainer,
  Description,
  ButtonContainer,
  Button,
} from "./styles";

import { PATHS } from "../../config/urls";
import MediaRender from "../Common/MediaRender";

// Function to format the date
const formatDate = (dateString) => {
  const [year, day, month] = dateString.split("-");
  const fullYear = year.length === 2 ? `20${year}` : year;
  return `${month}/${day}/${fullYear}`;
};

const PersonMemorialInfoCardComponent = ({ data }) => {
  const cardDataInfo = data?.cms?.memorials?.data;

  return (
    <MainContainer>
      {cardDataInfo.map((card) => (
        <Card key={card.attributes.id}>
          <CardImage>
            <MediaRender mediaSource={card.attributes.Image} />
          </CardImage>
          <DataContainer>
            <FullNameContainer>
              <CardText>{card.attributes.Name}</CardText>
              <CardText>{card.attributes.Last_Name}</CardText>
            </FullNameContainer>
            <DateContainer>
              <DateTextStyles>
                {formatDate(card.attributes.Birthdate)} -
              </DateTextStyles>
              <DateTextStyles>
                {formatDate(card.attributes.Date_Of_Death)}
              </DateTextStyles>
            </DateContainer>
          </DataContainer>
          <DescriptionContainer className="description">
            <Description>
              <Markdown text={card.attributes.Bio} />
            </Description>
            <ButtonContainer>
              <Link
                to={`${PATHS.MEMORIALBIO}/${transformToSlug(
                  `${card.attributes.Name} ${card.attributes.Last_Name}`
                )}`}
              >
                <Button>See Bio</Button>
              </Link>
              <Link
                to={`${PATHS.MEMORIALBIO}/${transformToSlug(
                  `${card.attributes.Name} ${card.attributes.Last_Name}`
                )}?openMessage=true`}
              >
                <Button>Leave a message</Button>
              </Link>
            </ButtonContainer>
          </DescriptionContainer>
        </Card>
      ))}
    </MainContainer>
  );
};

const PersonMemorialInfoCard = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          cms {
            memorials(pagination: { limit: -1 }) {
              data {
                id
                attributes {
                  Bio
                  Birthdate
                  Date_Of_Death
                  Last_Name
                  Name
                  Image {
                    ...Media
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <PersonMemorialInfoCardComponent data={data} />}
    />
  );
};

export default PersonMemorialInfoCard;
