import styled from "styled-components";
import {
  MOBILE_460,
  TABLET_600,
  TABLET_768,
  TABLET_800,
  TABLET_992,
} from "../../styles/globals/sizes";

export const MainContainer = styled.div`
  width: 100%;
  max-width: 1300px;
  display: grid;
  grid-template-columns: repeat(4, 268px);
  justify-content: center;
  margin: 60px auto;
  gap: 20px;
  @media (max-width: 1300px) {
    grid-template-columns: repeat(3, 268px);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 268px);
  }
  @media (max-width: ${TABLET_992}px) {
    grid-template-columns: repeat(2, 268px);
  }
  @media (max-width: ${TABLET_800}px) {
    grid-template-columns: repeat(2, 268px);
  }
  @media (max-width: ${TABLET_768}px) {
    grid-template-columns: repeat(2, 268px);
    margin: 20px auto;
  }
  @media (max-width: ${TABLET_600}px) {
    grid-template-columns: repeat(1, 268px);
  }
  @media (max-width: ${MOBILE_460}px) {
    gap: 0;
  }
`;

export const Card = styled.a`
  width: 268px;
  height: 396px;
  margin: 0 auto;
  border-radius: 32px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  &:hover .description {
    opacity: 1;
    visibility: visible;
  }
  @media (max-width: ${MOBILE_460}px) {
    margin-block: 15px;
  }
`;

export const DataContainer = styled.div`
  background-color: #404040;
  border-radius: 0 0 23px 23px;
  z-index: 2;
  position: relative;
  padding: 7px 0 13px 0;
`;

export const CardImage = styled.div`
  width: 100%;
  height: 302px;
  z-index: 1;
`;

export const FullNameContainer = styled.div`
  padding: 8px 8px 0;
  text-align: center;
`;

export const CardText = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  color: #fff;
`;

export const DateContainer = styled.div`
  padding: 8px 8px 0;
  text-align: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;

export const DateTextStyles = styled.p`
  margin: 0;
  font-size: 14px;
  color: #fff;
`;

export const DescriptionContainer = styled.div`
  width: 268px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 32px;
  background-color: rgba(64, 64, 64, 1);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 30px;
  box-sizing: border-box;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 3;
  @media (max-width: ${MOBILE_460}px) {
    width: auto;
  }
`;

export const Description = styled.p`
  max-height: 223px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 11;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 20px;
  font-family: Roboto;
  color: #ffffff;
  p {
    color: #ffffff;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 18px;
`;

export const Button = styled.button`
  width: 162px;
  height: 35px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 23px;
  font-family: Roboto;
  font-size: 16px;
  color: #ec4747;
  border: none;
  cursor: pointer;
  margin-top: 14px;
  &:hover {
    font-weight: bold;
  }
`;
